import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Badge, Card } from 'tunggal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Badge`}</inlineCode>{` adalah sebuah deskriptor untuk membantu menekankan status dari konten yang terkait.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <h3>{`Basic`}</h3>
    <Card bordered mdxType="Card">
  <Badge color="neutral" variant="basic" style={{
        marginRight: 16
      }} mdxType="Badge">
  Neutral
  </Badge>
      <Badge color="primary" variant="basic" style={{
        marginRight: 16
      }} mdxType="Badge">
  Primary
      </Badge>
      <Badge color="secondary" variant="basic" style={{
        marginRight: 16
      }} mdxType="Badge">
  Secondary
      </Badge>
      <Badge color="approval" variant="basic" style={{
        marginRight: 16
      }} mdxType="Badge">
  Approval
      </Badge>
  <Badge color="danger" variant="basic" style={{
        marginRight: 16
      }} mdxType="Badge">
    Danger
  </Badge>
    </Card>
    <h3>{`Basic Subtle`}</h3>
    <Card bordered mdxType="Card">
      <Badge color="neutral" variant="outline" style={{
        marginRight: 16
      }} mdxType="Badge">
  Neutral
      </Badge>
      <Badge color="primary" variant="outline" style={{
        marginRight: 16
      }} mdxType="Badge">
  Primary
      </Badge>
      <Badge color="secondary" variant="outline" style={{
        marginRight: 16
      }} mdxType="Badge">
  Secondary
      </Badge>
      <Badge color="approval" variant="outline" style={{
        marginRight: 16
      }} mdxType="Badge">
  Approval
      </Badge>
      <Badge color="danger" variant="outline" style={{
        marginRight: 16
      }} mdxType="Badge">
  Danger
      </Badge>
    </Card>
    <h3>{`Indicator`}</h3>
    <Card bordered mdxType="Card">
      <Badge color="neutral" variant="indicator" style={{
        marginRight: 16
      }} mdxType="Badge">
  Neutral
      </Badge>
      <Badge color="primary" variant="indicator" style={{
        marginRight: 16
      }} mdxType="Badge">
  Primary
      </Badge>
      <Badge color="secondary" variant="indicator" style={{
        marginRight: 16
      }} mdxType="Badge">
  Secondary
      </Badge>
      <Badge color="approval" variant="indicator" style={{
        marginRight: 16
      }} mdxType="Badge">
  Approval
      </Badge>
      <Badge color="danger" variant="indicator" style={{
        marginRight: 16
      }} mdxType="Badge">
  Danger
      </Badge>
    </Card>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      